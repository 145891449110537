import { Nav, Row, Col, Button, OverlayTrigger, Popover } from 'react-bootstrap';
import { RxDesktop, RxHome } from 'react-icons/rx'

import { UserInitials } from './../../';

import { types, variables, helpers } from 'utilities';



const ProfileDropdown: React.FC<types.IntProfileDropdown> = ({ userAttributes, handleSignOut }: types.IntProfileDropdown) => {
  // Declare a state variable to hold the authContext value


     
  return (
    <>
      <>
        <OverlayTrigger trigger="click" rootClose key="navOverlayTrigger1" placement="bottom" overlay={
          <Popover id={'popover-nav-1'} className='k-z-ind-999999 k-no-pop-arrow k-min-w-200 mt-1'>
            <Popover.Header as="h3" className='k-h-30'>
              <Row>
                <Col>
                                    
                </Col>
              </Row>
              <Row>
                <Col>
                  <div className='k-center-content '>
                    <UserInitials input={userAttributes && userAttributes.displayName} backgroundColor="#1f618d" width="50px" height="50px" fontSize="16px" border="2px solid white" />
                  </div>
                </Col>
              </Row>
            </Popover.Header>
            <Popover.Body className='p-0 pb-2'>
                              
              <Row className='mt-4 pt-3 mb-2'>
                <Col>
                  <Row><Col><span className="fs-14 k-center-content">{userAttributes &&  userAttributes.displayName || 'No Username'}</span></Col></Row>
                  <Row><Col><span className="text-muted fs-13 k-center-content">{userAttributes && userAttributes.email || 'No Email'}</span></Col></Row>
                </Col>
              </Row>

              <Row className="m-0 p-0 pt-2 pb-3 justify-content-md-center">
                <Col md="auto" className='p-1' >
                  <a className='k-reset-a-style' href='/'>
                    <div className="k-hover-grey-bg" style={{ borderRadius: '50%', border: '1px solid #f3f4f7', backgroundColor: 'white', cursor: 'pointer', width: '35px', height: '35px', justifyContent: 'center', alignItems: 'center',display: 'flex', }}>
                      <RxHome size={20}/>
                    </div>
                  </a>
                </Col>
                <Col md="auto" className='p-1'>
                  <a className='k-reset-a-style' href='/dashboard'>
                    <div className="k-hover-grey-bg" style={{ borderRadius: '50%', border: '1px solid #f3f4f7', backgroundColor: 'white', cursor: 'pointer', width: '35px', height: '35px', justifyContent: 'center', alignItems: 'center',display: 'flex', }}>
                      <RxDesktop size={20}/>
                    </div>
                  </a>
                </Col>
              </Row>
                              
              <Nav>
                                  
          
                <hr className='k-border-top-grey w-100-pc mt-1 mb-1'></hr>
                <span className='ps-2 ms-2 mb-1 txt-10 txt-bold color-lightText'>Settings</span>                                    
                <a className='p-2 ps-3 ms-2 me-2 k-hover-grey-bg k-pointer-item txt-12 b-rad-5' style={{color: 'inherit'}} href='/settings'>Account Settings</a>
                {/* 
                  * if user is admin: show admin navs
                 */}
                {userAttributes && userAttributes.role === variables.DEFAULT_USER_ROLES.ADMINISTRATOR ?
                  <a className='p-2 ps-3 ms-2 me-2 k-hover-grey-bg k-pointer-item txt-12 b-rad-5' style={{color: 'inherit'}} href='/admin'>Platform Administration</a>
                  :
                  null
                }
                {/* 
                  * End of Admin Navs
                 */}

                <hr className='k-border-top-grey w-100-pc mt-1 mb-1'></hr>
                <div className='p-2 ps-3 ms-2 me-2 k-hover-grey-bg k-pointer-item txt-12 b-rad-5' id='k-nav-logout-btn' onClick={handleSignOut}>Log Out</div>
                                  
              </Nav>
            </Popover.Body>
          </Popover>
        }>
          <Button variant='link' id='k-nav-profile-btn' className="k-bg-trans m-0 p-0 ms-2 mt-2 k-border-none k-center-content-hor-right k-no-button">
            <span className="fw-semibold fs-13 d-block line-height-normal">
              <UserInitials input={userAttributes && userAttributes.displayName} backgroundColor="white" width="30px" height="30px" fontSize="12px" color="black" border="1px solid black" />
            </span>
          </Button>
        </OverlayTrigger>
      </>
    </>
  )

    
};

export default ProfileDropdown;
